import React, { Component } from 'react';

class NavBar extends Component {
  render() {
    var logo = process.env.PUBLIC_URL + '/image/logo.png';
    return (
      <nav className="navbar navbar-expand-lg">
        <div className="container">
            <a className="navbar-brand" href="/">
                <img src={logo} alt=''/>
                <div>
                    <span className="company">Hải Thọ</span>
                    <span className="slogan">Cơ khí - chế tạo</span>
                </div>
                
            </a>
            <div className="navbar-link">
                <ul className="navbar-nav flex-row ml-md-auto d-md-flex">
                    <li className="nav-item">
                        <a className="nav-link active" href="/">Trang chủ</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/" >Lịch sử</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/">Con người</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/">Liên hệ</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    );
  }
}

export default NavBar;
