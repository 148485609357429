import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
        <div className="container">
            <div className="row pt-4 pb-2">
                <div className="col-xs-12 col-md-12">
                    <h3 className="text-center">CÔNG TY TNHH MTV HẢI THỌ</h3>
                    <p><span className="oi oi-wrench"></span> Chuyên cung cấp các dịch vụ cơ khí công nghệ cao (chế tạo - sửa chữa)</p>
                    <p><span className="oi oi-home"></span> 167/4 Núi Thành, Phường Hoà Cường Bắc, Quận Hải Châu, Đà Nẵng</p>
                    <p><span className="oi oi-phone"></span> 0905 866 166</p>
                </div>
            </div>
        </div>
    );
  }
}

export default About;
