import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './assets/style/site.css'
import './assets/open-iconic/font/css/open-iconic-bootstrap.css'
import NavBar from './components/navbar/NavBar';
import Slide from './components/slide/Slide';
import About from './components/about/About';
import Service from './components/service/Service';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<NavBar />, document.getElementById('navBar'));
ReactDOM.render(<Slide />, document.getElementById('slide'));
ReactDOM.render(<About />, document.getElementById('about'));
ReactDOM.render(<Service />, document.getElementById('service'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
