
import React, { Component } from 'react';
// import './swiper.css';
// import './swiper.js';

class Slide extends Component {
    render() {
        var imageDir = process.env.PUBLIC_URL + '/image/';
        const image1 = imageDir + 'father_and_daughter.jpg';
        const image2 = imageDir + 'caosu-1.jpg';
        const image3 = imageDir + 'caosu-2.jpg';
        return (
            <div className="swiper-container swiper-container-initialized swiper-container-horizontal">
                <div className="swiper-wrapper">
                    <div className="swiper-slide" style={{backgroundImage: "url('" + image1 +"')"}}></div>
                    <div className="swiper-slide" style={{backgroundImage: "url('" + image2 +"')"}}></div>
                    <div className="swiper-slide" style={{backgroundImage: "url('" + image3 +"')"}}></div>
                </div>

                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
            </div>
        );
    }
}

export default Slide;