import React, { Component } from 'react';

class Service extends Component {
  render() {
    var s1 = process.env.PUBLIC_URL + '/image/service-1.jpg';
    var s2 = process.env.PUBLIC_URL + '/image/service-2.jpg';
    var s3 = process.env.PUBLIC_URL + '/image/service-3.jpg';
    return (
        <div className="container">
            <div className="row pt-4 pb-2">
                <div className="col-xs-12 col-md-12">
                    <h3 className="text-center service-header">Các dịch vụ cung cấp</h3>
                    <div className="row no-gutters">
                      <div className="col-xs-12 col-md-4">
                        <div className="service-img" style={{backgroundImage: "url('" + s1 +"')"}}></div>
                        <span className="d-block service-title">Chế tạo cơ khí</span>
                        <span className="d-block service-description">Sản xuất thiết bị máy móc phục vụ sản xuất</span>
                      </div>

                      <div className="col-xs-12 col-md-4">
                        <div className="service-img" style={{backgroundImage: "url('" + s2 +"')"}}></div>
                        <span className="d-block service-title">Sửa chữa cơ khí máy móc</span>
                        <span className="d-block service-description">Chế tạo, thay thế thiết bị cơ khí cũ</span>
                      </div>

                      <div className="col-xs-12 col-md-4">
                        <div className="service-img" style={{backgroundImage: "url('" + s3 +"')"}}></div>
                        <span className="d-block service-title">Nạo vét - hút cát</span>
                        <span className="d-block service-description">Hút cát xây dựng, nạo vét lòng hồ, kênh mương</span>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default Service;
